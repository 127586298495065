import React from "react"
import ClientSearch from "../components/ClientSearch"
import Layout from "../components/layout"


const SearchTemplate = props => {
  const { pageContext } = props
  const { contentData } = pageContext
  const { allData, options } = contentData
  return (
    <Layout>
    <div>
      <h1 style={{ marginTop: `3em`, textAlign: `center` }}>
        Site search<br /><br />
      </h1>
      <div className="content" style={{padding: "1rem 1rem 2.5rem 1rem"}}>
        <ClientSearch content={allData} engine={options} />
      </div>
    </div>
    </Layout>
  )
}

export default SearchTemplate